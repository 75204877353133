.poster-card-container {
    align-items: center;
    border-radius: .2rem;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.poster-card-image {
    height: 100%;
}
