.poster-card-category-row-container {
    margin-bottom: .5rem;
}

.poster-card-category-row-title-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 .8rem .8rem .5rem;
    font-size: 1rem;
}

.poster-card-category-row-nav-button-container {
    display: none;
}

.poster-card-category-row {
    width: 100%;
    --poster-card-category-row-spacing: .8rem;
}

.poster-card-category-row swiper-slide {
    width: min-content;
    margin: 0 var(--poster-card-category-row-spacing);
}

.poster-card-category-row-container {
    --poster-card-height: 12rem;
    --poster-card-width: calc(var(--poster-card-height) * 2 / 3);
}

.poster-card-category-row-container .poster-card-container {
    height: var(--poster-card-height);
    width: var(--poster-card-width);
}

@media only screen and (min-width: 550px) {
    .poster-card-category-row swiper-slide:first-of-type {
        margin-left: calc(2 * var(--poster-card-category-row-spacing));
    }
    
    .poster-card-category-row swiper-slide:last-of-type  {
        margin-right: calc(2 * var(--poster-card-category-row-spacing));
    }

    .poster-card-category-row-title-container {
        font-size: 1.2rem;
        margin: 0 .9rem .9rem 1rem;
    }

    .poster-card-category-row-nav-button-container {
        align-items: center;
        display: flex;
    }

    .poster-card-category-row-nav-button {
        font-size: 1.7rem;
        margin-left: .7rem;
    }

    .poster-card-category-row-container {
        --poster-card-height: 12rem;
    }

    .poster-card-category-row-container {
        margin-bottom: 1.5rem;
    }
}

@media only screen and (min-width: 800px) {
    .poster-card-category-row-title-container {
        font-size: 1.3rem;
        margin: 0 .9rem .9rem 1rem;
    }

    .poster-card-category-row-nav-button-container {
        margin-right: .2rem;
    }

    .poster-card-category-row-nav-button {
        font-size: 1.9rem;
        margin-left: .8rem;
    }

    .poster-card-category-row-container {
        --poster-card-height: 15rem;
    }

    .poster-card-category-row-container {
        margin-bottom: 2.5rem;
    }
}